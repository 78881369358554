<template>
  <section class="my-10">
    <h2 class="text-2xl font-normal font-poppins">Invited Users</h2>
    <div class="mx-10 mt-10">
      <TableList :items="tokens" class="space-y-8">
        <template #columns>
          <div class="grid w-full grid-cols-6 font-semibold font-poppins">
            <span class="col-span-2">Email Address</span>
            <span class="col-span-3">Role</span>
            <span class="justify-self-end">Actions</span>
          </div>
        </template>
        <template #row="{ item }">
          <span class="col-span-2 text-gray-600 font-openSans">{{
            item.email
          }}</span>
          <span class="col-span-3 text-gray-600 font-openSans">{{
            item.role.title
          }}</span>
          <div class="flex gap-x-4 justify-self-end">
            <button @click="resendActivationEmail(item)">
              <send-icon
                class="inline-block w-6 h-6 text-gray-600 hover:text-yellow-500 transition-all duration-300 ease-out"
              />
            </button>
            <button @click="deleteToken(item)">
              <delete-icon
                class="inline-block w-6 h-6 text-gray-600 hover:text-yellow-500 transition-all duration-300 ease-out"
              />
            </button>
          </div>
        </template>
      </TableList>
    </div>
  </section>
  <hr class="text-gray-100" />
  <section class="my-10">
    <h2 class="text-2xl font-normal font-poppins">Add User</h2>
    <div class="mt-10 ml-10">
      <AddUserForm @success="fetch" />
    </div>
  </section>
  <ConfirmModal id="delete-user-token" title="Delete Invited User" />
</template>

<script>
import TableList from '@/components/lists/TableList.vue';
import AddUserForm from '@/components/forms/AddUserForm.vue';
import { useStore } from 'vuex';
import { onMounted } from 'vue';
import { useState, useModal, useToast } from '@/helpers/composables';

export default {
  components: {
    TableList,
    AddUserForm,
  },
  setup() {
    const store = useStore();
    const user = store.state.user.user;
    const [tokens, setTokens] = useState([]);
    const { open: openDeleteTokenModal } = useModal('delete-user-token');
    const toast = useToast();

    onMounted(() => {
      fetch();
    });

    async function fetch() {
      const { data } = await store.dispatch('get', { action: '/users/tokens' });
      setTokens(data);
    }
    async function resendActivationEmail({ id }) {
      const { message } = await store.dispatch('post', {
        action: '/users/tokens',
        param: id,
      });
      toast.show(message);
    }

    function deleteToken({ id, email }) {
      openDeleteTokenModal({
        body: `Are you sure you want to delete ${email} from the system?`,
        confirm: {
          text: 'Yes, Delete',
          action: async ({ close }) => {
            const { message } = await store.dispatch('delete', {
              action: '/users/tokens',
              param: id,
            });
            toast.show(message);
            close();
            fetch();
          },
        },
      });
    }

    return {
      user,
      tokens,
      resendActivationEmail,
      deleteToken,
      fetch,
    };
  },
};
</script>
