<template>
  <Form
    :validation-schema="validationSchema"
    action="/users/tokens"
    @success="handleSuccess"
    @error="handleError"
  >
    <template #default="{ meta }">
      <div class="flex space-x-8">
        <Field
          type="email"
          name="email"
          label="Email"
          placeholder="Email"
          class="w-96"
        />
        <RoleSelect />
      </div>
      <Button
        :variant="meta.valid && meta.dirty ? 'primary' : 'gray'"
        :disabled="!meta.valid && meta.dirty"
        class="w-36 mt-8 mb-12"
        >Invite User <plus-icon class="inline-block w-3 h-3 ml-2 -mt-1"
      /></Button>
    </template>
  </Form>
</template>

<script>
import { object, string, number } from 'yup';
import { useToast } from '@/helpers/composables';
export default {
  emits: ['success'],
  setup(props, { emit }) {
    const toast = useToast();   
    const validationSchema = object({
      email: string().email().required(),
      roleId: number().integer().min(1).required(),
    });

    function handleSuccess(event) {
      email.value = null;
      roleId.value = null;
      emit('success', event);
      toast.show(event.message);
    }

    function handleError(event) {
      toast.show(event.errors[0].message,'error');
    }

    return {
      validationSchema,
      handleSuccess,
      handleError
    };
  },
};
</script>
