<template>
  <div class="grid w-full" :class="`grid-cols-${columns}`">
    <slot name="row" :item="item" />
  </div>
</template>

<script>
export default {
  props: {
    item: {
      type: Object,
      default: () => {},
    },
    columns: {
      type: Number,
      default: () => 6,
    },
  },
};
</script>
