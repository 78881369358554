<template>
  <div>
    <slot name="columns" />
    <TableListItem
      v-for="(item, index) in items"
      :key="index"
      :item="item"
      :columns="columns"
    >
      <template #row="{ item }">
        <slot name="row" :item="item" />
      </template>
    </TableListItem>
  </div>
</template>

<script>
import TableListItem from './TableListItem.vue';

export default {
  components: {
    TableListItem,
  },
  props: {
    items: {
      type: Array,
      default: () => [],
    },
    columns: {
      type: Number,
      default: () => 6,
    },
  },
};
</script>
