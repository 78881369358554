<template>
  <div class="h-full min-h-full px-4 pt-16 bg-white lg:px-16">
    <div class="flex gap-x-12">
      <h1 class="text-4xl font-normal font-poppins">User Management</h1>
    </div>
    <p class="mt-6 font-poppins">
      Manage your organization's users. Create and update user profiles.
    </p>
    <UsersTable />
    <hr class="text-gray-100" />
    <InvitedUsersTable />
  </div>
</template>

<script>
import UsersTable from '@/components/tables/UsersTable.vue';
import InvitedUsersTable from '@/components/tables/InvitedUsersTable.vue';

export default {
  components: {
    UsersTable,
    InvitedUsersTable,
  },
  setup() {},
};
</script>
