<template>
  <section class="my-10">
    <h2 class="text-2xl font-normal font-poppins">Users</h2>
    <div class="mx-10 mt-10">
      <TableList :items="users" class="space-y-8">
        <template #columns>
          <div class="grid w-full grid-cols-6 font-semibold font-poppins">
            <span class="col-span-2">Email Address</span>
            <span class="col-span-3">Role</span>
            <span class="justify-self-end">Actions</span>
          </div>
        </template>
        <template #row="{ item }">
          <span class="col-span-2 text-gray-600 font-openSans">{{
            item.email
          }}</span>
          <span class="col-span-3 text-gray-600 font-openSans">{{
            item.role.title
          }}</span>
          <div class="flex gap-x-4 justify-self-end">
            <button
              v-if="user.roleId === 1"
              @click="
                item.id !== user.id && item.roleId !== 1 && startProxy(item)
              "
            >
              <account-icon
                class="inline-block w-6 h-6 text-gray-600 transition-all duration-300 ease-out opacity-25 cursor-default"
                :class="{
                  'opacity-100 hover:text-yellow-500 cursor-pointer':
                    item.id !== user.id && item.roleId !== 1,
                }"
              />
            </button>
            <button
              @click="
                item.id !== user.id && item.roleId !== 1 && updateUser(item)
              "
            >
              <edit-icon
                class="inline-block w-6 h-6 text-gray-600 transition-all duration-300 ease-out opacity-25 cursor-default"
                :class="{
                  'opacity-100 hover:text-yellow-500 cursor-pointer':
                    item.id !== user.id && item.roleId !== 1,
                }"
              />
            </button>
            <button
              @click="
                item.id !== user.id && item.roleId !== 1 && deleteUser(item)
              "
            >
              <delete-icon
                class="inline-block w-6 h-6 text-gray-600 transition-all duration-300 ease-out opacity-25 cursor-default"
                :class="{
                  'opacity-100 hover:text-yellow-500 cursor-pointer':
                    item.id !== user.id && item.roleId !== 1,
                }"
              />
            </button>
          </div>
        </template>
      </TableList>
    </div>
  </section>
  <section class="my-10">
    <div class="mt-10 ml-10">
      <UpdateUserModal />
    </div>
  </section>
  <ConfirmModal id="delete-user" title="Delete User" />
</template>

<script>
import TableList from '@/components/lists/TableList.vue';
import UpdateUserModal from '@/components/overlays/modals/UpdateUserModal.vue';
import { useStore } from 'vuex';
import { onMounted } from 'vue';
import { useRouter } from 'vue-router';
import { useState, useModal, useToast } from '@/helpers/composables';

export default {
  components: {
    TableList,
    UpdateUserModal,
  },
  setup() {
    const router = useRouter();
    const store = useStore();
    const user = store.state.user.user;
    const [users, setUsers] = useState([]);
    const { open: openUpdateUserModal } = useModal('update-user');
    const { open: openDeleteUserModal } = useModal('delete-user');
    const toast = useToast();

    onMounted(() => {
      fetch();
    });

    async function fetch() {
      const { data } = await store.dispatch('get', { action: '/users' });
      setUsers(data);
    }

    async function startProxy({ id }) {
      const { message } = await store.dispatch('user/proxy', {
        formData: { id },
        method: 'post',
      });
      toast.show(message);
      router.push({ name: 'dashboard' });
    }

    function updateUser(user) {
      openUpdateUserModal({
        user,
        fetch,
      });
    }

    function deleteUser({ id, email }) {
      openDeleteUserModal({
        body: `Are you sure you want to delete ${email} from the system?`,
        confirm: {
          text: 'Yes, Delete',
          action: async ({ close }) => {
            const { message } = await store.dispatch('delete', {
              action: '/users',
              param: id,
            });
            toast.show(message);
            close();
            fetch();
          },
        },
      });
    }

    return {
      user,
      users,
      startProxy,
      updateUser,
      deleteUser,
      fetch,
    };
  },
};
</script>
