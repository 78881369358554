<template>
  <Modal id="update-user" title="Update User">
    <template #default="{ close, context }">
      <Form
        class="mt-4 space-y-6"
        :validationSchema="validationSchema"
        method="patch"
        action="/users/role"
        :param="context.options.user.id"
        @success="handleSuccess($event, close, context)"
      >
        <RoleSelect :modelValue="context.options.user.roleId" />
        <div class="text-center">
          <Button class="mr-2 w-1/3">Update</Button>
          <Button class="ml-2 w-1/3" variant="secondary" @click="close">
            Cancel
          </Button>
        </div>
      </Form>
    </template>
  </Modal>
</template>

<script>
import { object, number } from 'yup';
import { useToast } from '@/helpers/composables';

export default {
  setup() {
    const toast = useToast();

    const validationSchema = object({
      roleId: number().integer().required(),
    });

    function handleSuccess({ message }, close, context) {
      context.options.fetch();
      toast.show(message);
      close();
    }

    return {
      validationSchema,
      handleSuccess,
    };
  },
};
</script>
